import React from "react"
import { Block } from "baseui/block"
import { SEOPage } from "../../components/SEO"
import Link from "../../components/Link"
import { Title, SubTitle, Description } from "../../components/typography"
import Layout from "../../components/layout"
import Container from "../../components/UI/Container"

// Use javascript to generate the content links

const content = [
  {
    contentKey: "23qwe",
    name: "FATF Red Flags Infographics",
    link:
      "https://storage.googleapis.com/cylynx-landing-content/fatf-virtual-asset-red-flag-indicators-cylynx.pdf",
  },
]

export default ({ location }) => {
  const info =
    location.state && location.state.contentKey
      ? content.find(x => x.contentKey === location.state.contentKey)
      : null
  return (
    <Layout isSimple>
      <SEOPage title="Content" />
      <Container>
        <Block marginTop="200px" />
        {info && (
          <>
            <Title>{info.name}</Title>
            <Description>
              <Link target="_blank" to={info.link}>
                Click here to download the file
              </Link>
            </Description>
          </>
        )}
        {!info && (
          <Title>
            Looking for something? Feel free to contact us for more information
          </Title>
        )}
        <Block marginBottom="200px" />
      </Container>
    </Layout>
  )
}
